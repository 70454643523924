import React, { ElementType } from 'react';
import classNames from 'classnames';
import Box from '../Box';
import Button, { ButtonProps } from '../Button';

import styles from './buttonLockup.module.scss';
import { MarginProps } from '../_internal/spacing';

export interface BaseProps<
  PrimaryElementType extends ElementType,
  TertiaryElementType extends ElementType,
> {
  /**
   * An override of the default HTML tag for the root container.
   * Can also be another React component.
   */
  as?: ElementType;
  /**
   * **Button** props configuring the primary action for the user to take
   */
  primary: ButtonProps<PrimaryElementType>;

  /**
   * **Link** props configuring the tertiary action for the user to take
   */
  tertiary?: ButtonProps<TertiaryElementType>;
}

interface WithSecondaryActionProps<SecondaryElementType extends ElementType> {
  /**
   * **Button** props configuring the secondary action from the user to take
   */
  secondary?: ButtonProps<SecondaryElementType>;
  /**
   * Whether or not the secondary action button is "dismissive."
   * This alters its positioning relative to the primary action button.
   *
   * @default false
   */
  secondaryIsDismissive?: boolean;
}
interface WithoutSecondaryActionProps {
  secondary?: undefined;
  secondaryIsDismissive?: undefined;
}

type SecondaryActionProps<SecondaryElementType extends ElementType> =
  | WithSecondaryActionProps<SecondaryElementType>
  | WithoutSecondaryActionProps;

export type ButtonLockupProps<
  PrimaryElementType extends ElementType,
  SecondaryElementType extends ElementType,
  TertiaryElementType extends ElementType,
> = BaseProps<PrimaryElementType, TertiaryElementType> &
  SecondaryActionProps<SecondaryElementType> &
  MarginProps;

const DEFAULT_BUTTON_WIDTH = {
  sm: 'fill',
  md: 'fixed',
};

/**
 * A button lock-up is a layout of primary, secondary and tertiary actions within forms, modals and other user interfaces. Its layout transforms depending on the screen size, which actions are included and the actions themselves.
 */
const ButtonLockup = <
  PrimaryElementType extends ElementType,
  SecondaryElementType extends ElementType,
  TertiaryElementType extends ElementType,
>({
  as,
  primary,
  secondary,
  secondaryIsDismissive,
  tertiary,
  m,
  mt,
  mb,
  my,
  ml,
  mr,
  mx,
}: ButtonLockupProps<
  PrimaryElementType,
  SecondaryElementType,
  TertiaryElementType
>) => {
  const marginProps = { m, mt, mb, my, ml, mr, mx };

  return (
    <Box
      as={as}
      className={classNames(styles.root, {
        [styles['has-secondary']]: secondary,
        [styles.dismissive]: secondaryIsDismissive,
      })}
      {...marginProps}
      role="group"
      data-testid="button-lockup"
    >
      <span className={styles.primary}>
        <Button
          variant="filled-staple"
          width={DEFAULT_BUTTON_WIDTH}
          {...primary}
        />
      </span>
      {secondary && (
        <span className={styles.secondary}>
          <Button
            variant="outline"
            width={DEFAULT_BUTTON_WIDTH}
            {...secondary}
          />
        </span>
      )}
      {tertiary && (
        <span className={styles.tertiary}>
          <Button
            variant="text"
            // This is here to preserve backwards compatibility:
            // We were originally passing as="a" here. We would
            // remove it but don't want to add a breaking change
            as={tertiary.href ? 'a' : undefined}
            {...tertiary}
          />
        </span>
      )}
    </Box>
  );
};

export default ButtonLockup;
