import React, { type ReactNode, type ElementType } from 'react';
import type { PolymorphicComponentProps } from '../_internal/components';
import Box, { type BoxProps } from '../Box';
import type { ButtonProps } from '../Button';
import Text from '../Text';
import type { MarginProps } from '../_internal/spacing';
import {
  processStylingProps,
  type DeprecatedAndDangerousStylingProps,
} from '../_internal/styling';

import ButtonLockup from '../ButtonLockup';
import Stack from '../Stack';

interface CommonProps extends DeprecatedAndDangerousStylingProps {
  /**
   * The main message of the empty state
   */
  children: ReactNode;
  /**
   * A title serving as a short description of the state
   */
  heading?: ReactNode;
  /**
   * A large image (ideally an SVG) serving as a visual description of the state
   */
  image?: ReactNode;
}

interface HasActionsProps<PAC extends ElementType, SAC extends ElementType> {
  /**
   * Props configuring the primary action button for the user to take
   */
  primaryAction: ButtonProps<PAC>;
  /**
   * Props configuring the secondary action button for the user to take
   */
  secondaryAction?: ButtonProps<SAC>;
}
interface NoActionsProps {
  primaryAction?: undefined;
  secondaryAction?: undefined;
}

type ActionProps<PAC extends ElementType, SAC extends ElementType> =
  | HasActionsProps<PAC, SAC>
  | NoActionsProps;

type Props<PAC extends ElementType, SAC extends ElementType> = CommonProps &
  ActionProps<PAC, SAC> &
  MarginProps;

export type EmptyStateProps<
  PAC extends ElementType,
  SAC extends ElementType,
  C extends ElementType = 'div',
> = PolymorphicComponentProps<C, Props<PAC, SAC>>;

/**
 * The `EmptyState` component is used when the page does not yet have data. `EmptyState` can also be used to display an error state by changing the messaging/iconography.
 */
const EmptyState = <
  PAC extends ElementType,
  SAC extends ElementType,
  C extends ElementType,
>({
  children,
  heading,
  primaryAction,
  secondaryAction,
  image,
  ...rootProps
}: EmptyStateProps<C, PAC, SAC>) => {
  // NOTE: rootProps has all of the props defined in `MarginProps` as well
  const boxProps = processStylingProps(rootProps, 'EmptyState', {
    stylingProps: 'warn',
    dangerousStylingProps: 'rewrite',
  }) as Partial<BoxProps>;

  return (
    <Box maxWidth="616px" {...boxProps}>
      {image && (
        <Stack spacing={0} mb={2.5} hAlign="center">
          {image}
        </Stack>
      )}
      {heading && (
        <Text setting="title-large" align="center" spacingBottom>
          {heading}
        </Text>
      )}
      <Text setting="body-medium" align="center">
        {children}
      </Text>
      {primaryAction && (
        <Stack spacing={0} mt={2} hAlign={{ md: 'center' }}>
          <ButtonLockup primary={primaryAction} secondary={secondaryAction} />
        </Stack>
      )}
    </Box>
  );
};

export default EmptyState;
